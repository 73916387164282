@import '../../../dark/base/base';

html {
  min-height: 100%;
}

body.dark {
  color: #888ea8;
  height: 100%;
  font-size: 0.875rem;
  background: #060818;
  overflow-x: hidden;
  overflow-y: auto;
  letter-spacing: 0.0312rem;
  font-family: 'Nunito', sans-serif;

  &:before {
    content: "";
    width: 100%;
    height: 0.85rem;
    position: fixed;
    top: 0;
    z-index: 1;
    left: 0;
    background: rgb(6 8 24 / 71%);
    -webkit-backdrop-filter: saturate(200%) blur(10px);
    backdrop-filter: saturate(200%) blur(10px);
  }

  h1, h2, h3, h4, h5, h6 {
    color: #e0e6ed;
  }
}

:focus {
  outline: none;
}

body.dark {
  p {
    margin-top: 0;
    margin-bottom: 0.625rem;
    color: #e0e6ed;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #515365;
  }

  strong {
    font-weight: 600;
  }

  code {
    color: $danger;
  }

  .page-header {
    border: 0;
    margin: 0;

    &:before {
      display: table;
      content: "";
      line-height: 0;
    }

    &:after {
      display: table;
      content: "";
      line-height: 0;
      clear: both;
    }
  }

  .page-title {
    float: left;
    margin-bottom: 16px;
    margin-top: 30px;

    h3 {
      margin: 0;
      margin-bottom: 0;
      font-size: 20px;
      color: #e0e6ed;
      font-weight: 600;
    }

    span {
      display: block;
      font-size: 11px;
      color: #555555;
      font-weight: normal;
    }
  }

  .main-container {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  #container.fixed-header {
    margin-top: 56px;
  }

  #content {
    width: 50%;
    flex-grow: 8;
    margin-top: 70px;
    margin-bottom: 0;
    margin-left: 255px;
    -webkit-transition: 0.3s ease all;
    transition: 0.3s ease all;
  }

  .main-container-fluid > .main-content > .container {
    float: left;
    width: 100%;
  }

  #content > .wrapper {
    -webkit-transition: margin ease-in-out .1s;
    -moz-transition: margin ease-in-out .1s;
    -o-transition: margin ease-in-out .1s;
    transition: margin ease-in-out .1s;
    position: relative;
  }

  .widget {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  }

  .layout-top-spacing {
    margin-top: 28px;
  }

  .layout-spacing {
    padding-bottom: 24px;
  }

  .layout-px-spacing {
    padding: 0 24px !important;
    min-height: calc(100vh - 112px) !important;
  }

  .widget.box .widget-header {
    background: #0e1726;
    padding: 0px 8px 0px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border: none;
  }

  .row [class*="col-"] .widget .widget-header h4 {
    color: #bfc9d4;
    font-size: 17px;
    font-weight: 600;
    margin: 0;
    padding: 16px 15px;
  }

  .seperator-header {
    background: transparent;
    box-shadow: none;
    margin-bottom: 40px;
    border-radius: 0;

    h4 {
      margin-bottom: 0;
      line-height: 1.4;
      padding: 5px 8px;
      font-size: 15px;
      border-radius: 4px;
      letter-spacing: 1px;
      display: inline-block;
      background: rgba(0, 150, 136, 0.26);
      color: #009688;
      font-weight: 500;
    }
  }

  .widget .widget-header {
    border-bottom: 0px solid #f1f2f3;

    &:before {
      display: table;
      content: "";
      line-height: 0;
    }

    &:after {
      display: table;
      content: "";
      line-height: 0;
      clear: both;
    }
  }

  .widget-content-area {
    padding: 20px;
    position: relative;
    background-color: #0e1726;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
  }

  .content-area {
    max-width: 58.333333%;
    margin-left: 80px;
  }

  .header-container {
    background: #191e3a;
    z-index: 1030;
    position: fixed;
    top: 0;
    margin-top: 0;
    right: 0;
    left: 255px;
    -webkit-transition: 0.3s left, 0s padding;
    transition: 0.3s left, 0s padding;
    backdrop-filter: blur(31px);
    padding: 15.5px 20px 15.5px 16px;
    min-height: 73.4688px;
    width: calc(100% - 255px);
    border-radius: 0;
    border: none;
    background-color: rgb(25 30 58 / 96%) !important;
    -webkit-backdrop-filter: saturate(200%) blur(6px);
    backdrop-filter: saturate(200%) blur(6px);
    box-shadow: 18px 20px 10.3px -23px rgb(0 0 0 / 15%);

    &.container-xxl {
      left: 255px;
    }
  }

  .navbar {
    padding: 0;
  }

  .navbar-brand {
    width: 5.5rem;
    padding-top: 0.0rem;
    padding-bottom: 0.0rem;
    margin-right: 0.0rem;
  }

  .navbar .border-underline {
    border-left: 1px solid #ccc;
    height: 20px;
    margin-top: 18px;
    margin-left: -5px;
    margin-right: 8px;
  }

  .navbar-expand-sm .navbar-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .navbar.navbar-expand-sm .navbar-item .nav-item {
    margin-left: 20px;
    align-self: center;
  }

  .navbar-expand-sm .navbar-item .nav-link {
    position: relative;
    padding: 0;
    text-transform: initial;
    z-index: 1;
  }

  .navbar {
    .toggle-sidebar, .sidebarCollapse {
      display: inline-block;
      position: relative;
      color: #bfc9d4;
    }

    .navbar-item .nav-item.theme-toggle-item .nav-link {
      padding: 4.24px 0;

      &:after {
        display: none;
      }
    }

    .light-mode {
      display: none;
    }
  }

  &:not(.light) .navbar .light-mode {
    display: none;
  }

  .navbar .dark-mode, &:not(.light) .navbar .dark-mode {
    display: inline-block;
    color: #bfc9d4;
    fill: #bfc9d4;
  }

  .navbar {
    .light-mode {
      display: none;
    }

    .dropdown-menu {
      border-radius: 8px;
      border-color: #e0e6ed;
    }

    .dropdown-item {
      line-height: 1.8;
      font-size: 0.96rem;
      padding: 15px 0 15px 0;
      word-wrap: normal;
    }

    .navbar-item .nav-item {
      &.dropdown.show a.nav-link span {
        color: #805dca !important;

        &.badge {
          background-color: #2196f3 !important;
          color: #fff !important;
        }
      }

      .dropdown-item {
        &.active, &:active {
          background-color: transparent;
          color: #16181b;
        }
      }

      &.dropdown {
        .nav-link:hover span {
          color: #805dca !important;
        }

        .dropdown-menu {
          /* top: 126%!important; */
          border-radius: 0;
          border: none;
          border-radius: 8px;
          -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
          background: #1b2e4b;
          left: auto;
          top: 23px !important;

          &.show {
            top: 38px !important;
          }

          .dropdown-item {
            border-radius: 0;
          }
        }
      }
    }

    .language-dropdown {
      a.dropdown-toggle {
        &:after {
          display: none;
        }

        img {
          width: 25px;
          height: 25px;
          border-radius: 8px;
        }
      }

      .dropdown-menu {
        min-width: 7rem;
        right: -8px !important;

        .dropdown-item {
          &:hover {
            background: transparent !important;
          }

          &.active, &:active {
            background: transparent;
            color: #16181b;
          }
        }

        a {
          img {
            width: 20px;
            height: 20px;
            margin-right: 16px;
            border-radius: 8px;
          }

          span {
            color: #bfc9d4;
            font-weight: 500;
          }
        }

        .dropdown-item:hover span {
          color: #fff !important;
        }
      }
    }

    .navbar-item .nav-item.dropdown.notification-dropdown {
      .nav-link {
        &:after {
          display: none;
        }

        svg {
          color: #bfc9d4;
          stroke-width: 1.5;
        }

        span.badge {
          position: absolute;
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          padding: 0;
          font-size: 10px;
          color: #fff !important;
          background: #00ab55;
          top: -5px;
          right: 2px;
        }
      }

      .dropdown-menu {
        min-width: 15rem;
        right: -8px;
        left: auto;
        padding: 0;

        .notification-scroll {
          height: 375px;
          position: relative;
        }

        .drodpown-title {
          padding: 14px 16px;
          border-bottom: 1px solid #191e3a;
          border-top: 1px solid #191e3a;
          margin-bottom: 10px;

          &.message {
            border-top: none;
          }

          h6 {
            margin-bottom: 0;
            font-size: 14px;
            letter-spacing: 1px;
            font-weight: 200;
            color: #bfc9d4;
          }
        }

        .dropdown-item {
          padding: 0.625rem 1rem;
          cursor: pointer;
          border-radius: 0;
          background: transparent;
        }

        .media {
          margin: 0;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 12px;
          border: 3px solid rgba(224, 230, 237, 0.16);
        }

        svg {
          width: 23px;
          height: 23px;
          font-weight: 600;
          color: $warning;
          margin-right: 9px;
        }

        .media {
          &.file-upload svg {
            color: $danger;
          }

          &.server-log svg {
            color: #009688;
          }
        }

        .media-body {
          display: flex;
          justify-content: space-between;
        }

        .data-info {
          display: inline-block;
          white-space: normal;

          h6 {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
            margin-right: 8px;
            color: #e0e6ed;
          }
        }

        .dropdown-item:hover .data-info h6 {
          color: #fff;
        }

        .data-info p {
          margin-bottom: 0;
          font-size: 13px;
          font-weight: 600;
          color: #888ea8;
        }

        .icon-status {
          white-space: normal;
          display: none;
        }

        .dropdown-item:hover .icon-status {
          display: block;
        }

        .icon-status svg {
          margin: 0;

          &.feather-x {
            color: #bfc9d4;
            width: 19px;
            height: 19px;
            cursor: pointer;

            &:hover {
              color: $danger;
            }
          }

          &.feather-check {
            color: #fff;
            background: #00ab55;
            border-radius: 50%;
            padding: 3px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    form.form-inline input.search-form-control {
      &::-webkit-input-placeholder, &::-ms-input-placeholder, &::-moz-placeholder {
        color: #888ea8;
        letter-spacing: 1px;
      }
    }

    .form-inline.search {
      .search-form-control {
        display: inline-block;
        background: transparent;
        border: none;
        padding: 8px 69px 8px 12px;
        cursor: pointer;
        width: 201px;
      }

      display: inline-block;
    }

    .search-animated {
      .badge {
        position: absolute;
        right: 6px;
        top: 6.5px;
        font-size: 11px;
        letter-spacing: 1px;
        transform: none;
        background-color: rgb(128 93 202 / 40%);
        color: #fff;
      }

      &.show-search {
        .badge {
          display: none;
        }

        position: initial;
      }

      position: relative;

      svg {
        font-weight: 600;
        cursor: pointer;
        position: initial;
        left: 1453px;
        color: #bfc9d4;
        stroke-width: 1.5;
        margin-right: 5px;
        margin-top: -3px;
        display: none;

        &.feather-x {
          display: none;
          width: 18px;
          height: 18px;
        }
      }

      &.show-search svg {
        &.feather-x {
          display: block;
          right: 12px;
          left: auto;
          top: 9px;
          z-index: 45;
        }

        margin: 0;
        position: absolute;
        top: 18px;
        left: 12px;
        color: #e0e6ed;
        z-index: 40;
        display: none;
      }
    }
  }

  &.search-active {
    .header-container {
      padding: 0;
    }

    .navbar {
      min-height: 62px;
    }

    .form-inline.search {

      position: absolute;
      bottom: 0;
      top: 0;
      background: #1b2e4b;

      width: 100%;
      left: 0;
      right: 0;
      z-index: 32;
      margin-top: 0px !important;
      display: flex;
      opacity: 1;
      transition: opacity 200ms, right 200ms;
      border-radius: 8px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -ms-flex-align: center;
      align-items: center;
      
      
      .search-form-control {
        opacity: 1;
        transition: opacity 200ms, right 200ms;

        &:focus {
          box-shadow: none;
        }
      }

      

      .search-bar {
        width: 100%;
        position: relative;
      }

      .search-form-control {
        background: transparent;
        display: block;
        padding-left: 16px;
        padding-right: 40px;
        border: none;
        width: 100%;
      }
    }
  }

  .search-overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: transparent !important;
    z-index: 814 !important;
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.show {
      display: block;
      opacity: .1;
    }
  }

  .navbar .navbar-item .nav-item {
    &.dropdown.user-profile-dropdown .nav-link:after {
      display: none;
    }

    &.user-profile-dropdown .dropdown-menu {
      padding: 0 10px 10px 10px !important;
      z-index: 9999;
      max-width: 13rem;
      right: 3px;
      left: auto;
      min-width: 11rem;

      &:after {
        border-bottom-color: #b1b2be !important;
      }

      .user-profile-section {
        padding: 16px 15px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin-right: -10px;
        margin-left: -10px;
        background: #1b2e4b;
        margin-top: -1px;
        margin-bottom: 10px;
        border-bottom: 1px solid #191e3a;

        .media {
          margin: 0;

          img {
            width: 40px;
            height: 40px;
            border-radius: 12px;
            border: 3px solid rgba(224, 230, 237, 0.16);
          }

          .emoji {
            font-size: 19px;
          }

          .media-body {
            align-self: center;

            h5 {
              font-size: 15px;
              font-weight: 600;
              margin-bottom: 3px;
              color: #bfc9d4;
            }

            p {
              font-size: 13px;
              font-weight: 500;
              margin-bottom: 0;
              color: #888ea8;
            }
          }
        }
      }
    }

    &.dropdown.user-profile-dropdown .nav-link:after {
      display: none;
    }

    &.user-profile-dropdown {
      .nav-link svg {
        color: #bfc9d4;
        stroke-width: 1.5;
      }

      .dropdown-menu {
        &.show {
          top: 45px !important;
        }

        .dropdown-item {
          padding: 0;
          background: transparent;

          a {
            display: block;
            color: #bfc9d4;
            font-size: 14px;
            font-weight: 500;
            padding: 6px 14px;
            border-radius: 8px;
          }

          &:hover a {
            color: #fff;
            background: #0e1726;
          }

          &.active, &:active {
            background-color: transparent;
          }

          svg {
            width: 18px;
            margin-right: 7px;
            height: 18px;
          }
        }
      }
    }
  }

  .sidebar-wrapper {
    width: 255px;
    position: fixed;
    z-index: 1030;
    transition: width .600s;
    height: 100vh;
    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: 5px 0 25px 0 #0e17260f;
  }

  .shadow-bottom {
    display: block;
    position: absolute;
    z-index: 2;
    height: 26px;
    width: 94%;
    pointer-events: none;
    margin-top: -15px;
    left: 6px;
    -webkit-filter: blur(5px);
    filter: blur(7px);
    background: -webkit-linear-gradient(#0e1726 41%, rgba(14, 23, 38, 0.839) 95%, rgba(14, 23, 38, 0.22));
    background: linear-gradient(#0e1726 41%, rgba(14, 23, 38, 0.839) 95%, rgba(14, 23, 38, 0.22));
  }

  .sidebar-theme {
    background: #0e1726;
  }

  .sidebar-closed > {
    .sidebar-wrapper {
      width: 84px;

      &:hover {
        width: 255px;
        box-shadow: 6px 0 10px 0 rgba(0, 0, 0, 0.14), 1px 0px 18px 0 rgba(0, 0, 0, 0.12), 3px 0 5px -1px rgba(0, 0, 0, 0.2);

        span {
          &.sidebar-label {
            display: inline-block;
          }
        }
        
      }

      span {
        &.sidebar-label {
          display: none;
        }
      }
      
    }

    #content {
      margin-left: 84px;
    }
  }

  #sidebar .theme-brand {
    background-color: #0e1726;
    padding: 10px 12px 6px 21px;
    border-bottom: 1px solid #0e1726;
    border-radius: 8px 6px 0 0;
    justify-content: space-between;
  }

  .sidebar-closed {
    #sidebar .theme-brand {
      padding: 18px 12px 13px 21px;
    }

    > .sidebar-wrapper:hover #sidebar .theme-brand {
      padding: 10px 12px 6px 21px;
    }
  }

  .sidebar-wrapper.sidebar-theme .theme-brand .nav-logo {
    display: flex;
  }

  #sidebar .theme-brand div.theme-logo {
    align-self: center;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .sidebar-closed .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle {
    display: none;
  }

  .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle {
    align-self: center;
    cursor: pointer;
    overflow: unset !important;

    .sidebarCollapse {
      position: relative;
      overflow: unset !important;

      &:before {
        position: absolute;
        content: "";
        height: 40px;
        width: 40px;
        background: #00000040;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
        left: -8px;
        right: 0;
        z-index: 0;
        opacity: 0;
      }

      &:hover:before {
        opacity: 1;
      }
    }

    .btn-toggle svg {
      width: 25px;
      height: 25px;
      color: #fff;
      transform: rotate(0);
      -webkit-transition: 0.3s ease all;
      transition: 0.3s ease all;

      polyline {
        &:nth-child(1) {
          color: #d3d3d3;
        }

        &:nth-child(2) {
          color: #888ea8;
        }
      }

      &:hover {
        color: #bfc9d4;
      }
    }
  }

  .sidebar-closed {
    .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle .btn-toggle svg {
      transform: rotate(-180deg);
    }

    #sidebar .theme-brand div.theme-text {
      display: none;
    }

    > .sidebar-wrapper:hover #sidebar .theme-brand {
      li.theme-text a, div.theme-text, .sidebar-toggle {
        display: block;
      }
    }
  }

  #sidebar {
    .theme-brand div.theme-text a {
      font-size: 25px !important;
      color: #e0e6ed !important;
      line-height: 2.75rem;
      padding: 0.39rem 0.8rem;
      text-transform: initial;
      position: unset;
      font-weight: 600;
    }

    .navbar-brand .img-fluid {
      display: inline;
      width: 44px;
      height: auto;
      margin-left: 20px;
      margin-top: 5px;
    }

    * {
      overflow: hidden;
      white-space: nowrap;
    }

    ul.menu-categories {
      position: relative;
      padding: 5px 0 20px 0;
      margin: auto;
      width: 100%;
      overflow: auto;

      &.ps {
        height: calc(100vh - 71px) !important;
      }

      li {
        > .dropdown-toggle[aria-expanded="true"] svg.feather-chevron-right {
          transform: rotate(90deg);
        }

        &.menu:first-child ul.submenu > li a {
          justify-content: flex-start;

          i {
            align-self: center;
            margin-right: 12px;
            font-size: 19px;
            width: 21px;
          }
        }
      }
    }
  }

  .sidebar-wrapper ul.menu-categories li.menu.menu-heading {
    height: 56px;

    > .heading .feather-minus {
      display: none;
      vertical-align: sub;
      width: 12px;
      height: 12px;
      stroke-width: 4px;
      color: #506690;
    }
  }

  .sidebar-closed .sidebar-wrapper {
    ul.menu-categories li.menu.menu-heading > .heading .feather-minus {
      display: inline-block;
    }

    &:hover ul.menu-categories li.menu.menu-heading > .heading .feather-minus {
      display: none;
    }
  }

  .sidebar-wrapper ul.menu-categories li.menu.menu-heading > .heading {
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    color: #506690;
    padding: 32px 0 10px 36px;
    letter-spacing: 1px;
  }

  .sidebar-closed {
    > .sidebar-wrapper {
      ul.menu-categories li.menu.menu-heading > .heading span {
        display: none;
      }

      &:hover ul.menu-categories li.menu.menu-heading > .heading span {
        display: inline-block;
      }
    }

    #sidebar ul.menu-categories li.menu > .dropdown-toggle {
      padding: 10px 16px;
      transition: .600s;
      position: relative;
    }

    > .sidebar-wrapper:hover #sidebar ul.menu-categories li.menu > .dropdown-toggle {
      transition: .600s;
    }

    .sidebar-wrapper:hover #sidebar ul.menu-categories li.menu > .dropdown-toggle[aria-expanded="true"]:before, #sidebar ul.menu-categories li.menu > .dropdown-toggle svg.feather-chevron-right {
      display: none;
    }

    .sidebar-wrapper:hover #sidebar ul.menu-categories li.menu > .dropdown-toggle {
      svg.feather-chevron-right {
        display: inline-block;
      }

      &[aria-expanded="true"] svg {
        padding: 0;
        background: transparent;
        border-radius: 0;
        border: none;
        width: auto;
        width: 20px;
        height: 20px;
      }
    }
  }

  #sidebar ul.menu-categories {
    li.menu {
      > .dropdown-toggle {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        font-size: 15px;
        color: #bfc9d4;
        padding: 10.2px 16px;
        font-weight: 400;
        transition: .600s;
        letter-spacing: 1px;
        margin-bottom: 2px;
        margin: 0 16px 0 16px;
        border-radius: 8px;
        margin-top: 2px;

        &.disabled {
          opacity: .5;
          cursor: default;
          color: #888ea8;
          svg:not(.bage-icon) {
            opacity: 0.5;
          }
          &:hover {
            color: #888ea8;
            svg:not(.bage-icon) {
              color: #888ea8;
              opacity: 0.5;
            }
          }
        }

        > div {
          align-self: center;

          span {
            &.sidebar-label {
              position: absolute;
              right: 12px;
              svg {
                width: 15px;
                height: 15px;
                vertical-align: sub;
              }
            }
          }
          
        }
      }

      .dropdown-toggle:after {
        display: none;
      }

      > .dropdown-toggle svg:not(.badge-icon) {
        color: #888ea8;
        margin-right: 6px;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        stroke-width: 1.8;
      }

      &.active > .dropdown-toggle {
        background-color: #4361ee;

        svg, span {
          color: #fff;
        }

        &[aria-expanded="true"] {
          background: rgba(255, 255, 255, 0.07);
        }

        &:hover {
          color: #fff;
  
          svg:not(.badge-icon) {
            color: #fff;
            fill: #4361ee0a;
          }
        }
        
      }

      > {
        .dropdown-toggle {
          &[aria-expanded="false"] svg.feather-chevron-right {
            transform: rotate(0);
            transition: .5s;
          }

          &[aria-expanded="true"] {
            svg {
              &.feather-chevron-right {
                background-color: transparent;
                transform: rotate(90deg);
                transition: .5s;
              }

              color: #ffffff;
              fill: none;
            }

            background: rgba(255, 255, 255, 0.07);

            span {
              color: #ffffff;
            }

            &:hover {
              color: #fff;
    
              svg {
                color: #fff!important;
                fill: #4361ee0a;
              }
            }
          }

          &:hover {
            color: #ffffff;

            svg:not(.badge-icon) {
              color: #ffffff;
            }
          }

          svg.feather-chevron-right {
            vertical-align: middle;
            margin-right: 0;
            width: 15px;
          }
        }

        a span:not(.badge) {
          vertical-align: middle;
        }
      }
    }

    ul.submenu > li a {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 10.2px 16px 10.2px 24px;
      margin-left: 34px;
      font-size: 15px;
      color: #bfc9d4;
    }

    li.menu ul.submenu > li {

      a {
        &:before {
          content: '';
          background-color: #d3d3d3;
          position: absolute;
          height: 7px;
          width: 7px;
          top: 18px;
          left: 5px;
          border-radius: 50%;
        }
        &:hover {
          color: $white;
          &:before {
            background: $white!important;
            box-shadow: 0 0 0px 2px rgba(255, 255, 255, 0.431);
            border: 1.9px solid #0e1726;
          }
        }
      }

      &.active {
        a {
          color: #fff;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          font-weight: 500;
          
          &:before {
            background-color: $white;
          }
  
          &:hover {
            color: #fff!important;
      
            &:before {
              background: #fff !important;
              box-shadow: 0 0 0px 2px rgb(255 255 255 / 43%);
              border: 1.9px solid #0e1726;
            }
          }
        }
      }
  
    }

    ul.submenu {
      > li {

        margin-top: 3px;
        
        &.active {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            background-color: rgba(255, 255, 255, 0.07);
            background-color: #4361ee;
            width: 15px;
            height: 42px;
            width: 100%;
            margin: 0 21px;
            border-radius: 6px;
            width: 87.5%;
            left: -5px;
            top: 1px;
          }
          
        }

        a {
          &:hover {
            color: #e0e6ed;

            &:before {
              background-color: #b1b2be;
            }
          }

          i {
            align-self: center;
            font-size: 9px;
          }
        }
      }

      li > {
        [aria-expanded="true"] {
          i {
            color: #fff;
          }

          &:before {
            background-color: #fff;
          }
        }

        a[aria-expanded="true"] {
          color: #009688;
          &:before {
            background-color: #009688!important;
          }
        }
      }

      > li {
        a.dropdown-toggle {
          padding: 10px 32px 10px 33px;

          svg {
            align-self: center;
            transition: .3s;
          }
        }

        ul.sub-submenu > li {
          a {
            position: relative;
            padding: 10px 12px 10px 48px;
            padding-left: 25px;
            margin-left: 72px;
            font-size: 15px;
            color: #bfc9d4;
            letter-spacing: 1px;
          }

          &.active a {
            color: #fff;
          }

          a {
            &:hover {
              color: #009688;

              &:before {
                background-color: #009688!important;
                border: 1.9px solid #009688;
                box-shadow: none;
              }
            }

            &:before {
              content: '';
              background-color: #bfc9d4;
              position: absolute;
              top: 18.5px !important;
              border-radius: 50%;
              left: 3px;
              height: 4px;
              width: 4px;
            }
          }

          &.active a:before {
            background-color: #009688;
          }
        }
      }
    }
  }

  .overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1035 !important;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .e-animated {
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .e-fadeInUp {
    -webkit-animation-name: e-fadeInUp;
    animation-name: e-fadeInUp;
  }

  /*  
    ======================
        Footer-wrapper
    ======================
  */

  .footer-wrapper {
    padding: 10px 0 10px 0;
    display: inline-block;
    background: transparent;
    font-weight: 600;
    font-size: 12px;
    width: 100%;
    border-top-left-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 10px 24px 10px 24px;
    margin: auto;
    margin-top: 15px;
  }

  .layout-boxed .footer-wrapper {
    max-width: 1488px;
  }

  .main-container.sidebar-closed .footer-wrapper {
    border-radius: 0;
  }

  .footer-wrapper .footer-section {
    p {
      margin-bottom: 0;
      color: #888ea8;
      font-size: 14px;
      letter-spacing: 1px;

      a {
        color: #888ea8;
      }
    }

    svg {
      color: $danger;
      fill: $danger;
      width: 15px;
      height: 15px;
      vertical-align: sub;
    }
  }

  &.alt-menu {
    .header-container {
      transition: none;
    }
    #content {
      transition: none;
    }
  }
}

/*Page title*/

/* 
=====================
    Navigation Bar
=====================
*/


/*   Language   */

/*   Language Dropdown  */

/*Notification Dropdown*/

/* Search */

/* User Profile Dropdown*/

/* 
===============
    Sidebar
===============
*/

@-webkit-keyframes e-fadeInUp {
  0% {
    opacity: 0;
    margin-top: 10px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes e-fadeInUp {
  0% {
    opacity: 0;
    margin-top: 10px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

/*  
    ======================
        Footer-wrapper
    ======================
*/

/*  
    ======================
        MEDIA QUERIES
    ======================
*/

@media (max-width: 991px) {
  body {
    &.dark {
      .header-container {
        padding-right: 16px;
        padding-left: 16px;
        left: 0;
        width: 100%;

        &.container-xxl {
          left: 0;
        }
      }

      .layout-px-spacing {
        padding: 0 16px !important;
      }

      .main-container.sidebar-closed #content {
        margin-left: 0;
      }

      .navbar {
        .search-animated {
          margin-left: auto;

          svg {
            margin-right: 0;
            display: block;
          }

          .badge {
            display: none;
          }
        }

        .form-inline.search {
          display: none;
        }
      }
      
      

      #content {
        margin-left: 0;
      }

      #sidebar .theme-brand {
        border-radius: 0;
        padding: 14px 12px 13px 21px;
      }

      .sidebar-closed {
        #sidebar .theme-brand {
          padding: 14px 12px 13px 21px;

          div.theme-text {
            display: block;
          }
        }

        .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle {
          display: block;
        }
      }

      .main-container:not(.sbar-open) .sidebar-wrapper {
        width: 0;
        left: -52px;
      }

      &.search-active .form-inline.search {
        display: flex;
      }
      
    }

    &.alt-menu .sidebar-closed > .sidebar-wrapper {
      width: 255px;
      left: -255px;
    }

    &.dark {
      .main-container {
        padding: 0;
      }

      #sidebar ul.menu-categories.ps {
        height: calc(100vh - 114px) !important;
      }

      .sidebar-wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 9999;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        border-radius: 0;
        left: 0;
      }

      .sidebar-noneoverflow {
        overflow: hidden;
      }

      #sidebar {
        height: 100vh !important;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
      }

      .overlay.show {
        display: block;
        opacity: .7;
      }
    }
  }

  

  /*
      =============
          NavBar
      =============
  */

  /*
      =============
          Sidebar
      =============
  */
}

@media (min-width: 992px) {
  .sidebar-noneoverflow body.dark .header-container {
    left: 84px;
    width: calc(100% - 84px);

    &.container-xxl {
      left: 84px;
    }
  }

  body.dark {
    .navbar {
      .toggle-sidebar, .sidebarCollapse {
        display: none;
      }
    }

    .sidebar-closed #sidebar .theme-brand li.theme-text a {
      display: none;
    }
  }
}

@media (max-width: 575px) {
  body.dark {
    .navbar .navbar-item .nav-item.dropdown {
      &.message-dropdown .dropdown-menu {
        right: auto;
        left: -76px !important;
      }

      &.notification-dropdown .dropdown-menu {
        right: -64px;
      }

      &.language-dropdown .dropdown-menu {
        right: auto !important;
        left: -56px !important;
      }
    }

    .footer-wrapper .footer-section.f-section-2 {
      display: none;
    }
  }
}